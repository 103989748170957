import {useEffect, useState} from "react";
import {
    getAppleAppUrl,
    getCookie, getCountryFromLocale,
    getDeviceArchitecture,
    getDownloadRedirectABTestingData,
    getIntentUrlWithFallback,
    setDownloadVariant,
    updateAppUrls
} from "../utils/constants/PagesConstants";
import {DEVICE_MODEL, INITIAL, NOT_FOUND, VariantA, VariantB} from "../utils/constants/GlobalConstants";
import {ABTestingData, BrandModelData} from "../types/Misc.type";
import Analytics from "../analytics/Analytics";
import {DownloadURLWithVariant} from "../analytics/AnalyticsEvents";
import {IAppVersionData, IDeviceArchitechture} from "../types/common";
import useIsIOS from "../utils/helpers/useIsIOS";

export const useApkUrl = (locale: string,
                                appVersions: IAppVersionData,
                                pageName: string,
                                abTestingData: ABTestingData,
                                deviceArchitectureMapping: IDeviceArchitechture,
                                brandModelData: BrandModelData,
                                isDownloadDisabled: boolean = false) => {

    const iosUrl: string = getAppleAppUrl(getCountryFromLocale(locale))

    const [apkUrl, setApkUrl] = useState(updateAppUrls(INITIAL, locale, appVersions, pageName))
    const [deviceModel, setDeviceModel] = useState<string | undefined>(undefined)
    const downloadRedirectData = getDownloadRedirectABTestingData(abTestingData);
    const isIosDevice = useIsIOS()

    useEffect(() => {
        if (!deviceModel) {
            // @ts-ignore
            if (getCookie(DEVICE_MODEL) === NOT_FOUND && typeof navigator.userAgentData !== "undefined") {
                // @ts-ignore
                navigator.userAgentData
                    .getHighEntropyValues(["model"])
                    .then((values) => {
                        setDeviceModel(values.model)
                    });
            } else {
                setDeviceModel(getCookie(DEVICE_MODEL))
            }
        }
    }, [])

    useEffect(() => {
        if (deviceModel) {
            let deviceArchitecture = getDeviceArchitecture(
                deviceArchitectureMapping,
                deviceModel || NOT_FOUND
            )

            //download url based on device brand
            let apkChanged = false;
            if (downloadRedirectData && downloadRedirectData.variant === VariantB && downloadRedirectData.executeTesting.includes(pageName)) {
                const brands = downloadRedirectData.brands;
                Object.entries(brandModelData).forEach(([key, value]) => {
                    if (brands[key]) {
                        for (const item of value) {
                            if (deviceModel === item) {
                                const fallBackUrl = updateAppUrls(deviceArchitecture, locale, appVersions, pageName);
                                const downloadUrlWithFallback = getIntentUrlWithFallback(brands[key], fallBackUrl);
                                setApkUrl(downloadUrlWithFallback)
                                apkChanged = true;
                                break
                            }
                        }
                    }
                })
            }

            if (!apkChanged) {
                setApkUrl(updateAppUrls(deviceArchitecture, locale, appVersions, pageName))
                Analytics.getInstance().logEvent(DownloadURLWithVariant(VariantA))
            } else {
                Analytics.getInstance().logEvent(DownloadURLWithVariant(VariantB))
                setDownloadVariant(VariantB);
            }
        }
    }, [deviceModel]);

    const getApkUrlFromDeviceType = () => {
        if (isDownloadDisabled) {
            return "#"
        }

        if (isIosDevice)
            return iosUrl;
        return apkUrl;
    }

    return {androidUrl: apkUrl, downloadUrl: getApkUrlFromDeviceType()}
}